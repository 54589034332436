import busMarker from "../Images/bus.png";
import React, { useRef } from "react";
import "./BusMaker.css"

const BusMaker=(props)=>{

    const busMarkerRef = useRef(null);

    const handleCenterClick = () => {
      if (busMarkerRef.current) {
        busMarkerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center"
        });
      }
    };



    return( 
    <img
        src={busMarker}
        alt="Marcador de Autobús"
        className="bus-marker"
        ref={busMarkerRef}
        style={{
          left: `${(props.data.x / 800) * 100}%`,
          top: `${(props.data.y / 1200) * 100}%`,
          width: props.data.size,
          height: props.data.size
        }
        
      }
    />
      );




}
export default BusMaker;