import React, { useState } from "react";
import "./Popup.css"; // Asegúrate de tener el archivo CSS correspondiente

const Popup = ({ onClose }) => {
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="close-button" onClick={onClose}>
          CERRAR
        </div>
        <p>
        Esta aplicación se encuentra en fase de desarrollo lo que significa que podría tener fallos en su funcionamiento.
        Cualquier inconveniente, duda o sugerencia podes contactarte a soporte@tornamap.com.ar
        </p>
        <p>
          Gracias por ser parte de este proceso. El lanzamiento oficial de Tornamap es en marzo del 2024.
        </p>
        <p>¡Qué disfrutes de Tornamap!</p>
        <p>¡Felices Fiestas!</p>
        <p>Pablo Daniel Bilocopetiuc.</p>
      </div>
    </div>
  );
};

export default Popup;
