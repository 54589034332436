// ImageSlider.js
import React, { useRef } from "react";
import "./ImageSlider.css";
import planoImage from "../Images/plano.png";
import busMarker from "../Images/bus.png";
import BusMaker from "./BusMaker";

const markerPosition = {
  x: 450,
  y: 200,
  size:"10vw"
};

const ImageSlider = () => {
  const imageWrapperRef = useRef(null);
  const busMarkerRef = useRef(null);

  const handleCenterClick = () => {
    if (busMarkerRef.current) {
      busMarkerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center"
      });
    }
  };

  return (
    <div className="slider-container">
      <div className="image-wrapper" ref={imageWrapperRef}>
        <div className="image-container">
          <img
            src={planoImage}
            alt="Imagen Deslizable"
            className="slider-image"
          />
          <BusMaker data={markerPosition}></BusMaker>
        </div>
      </div>
      {/* <button className="center-button" onClick={handleCenterClick}>
        Centrar
      </button> */}
    </div>
  );
};

export default ImageSlider;
