import React from "react";
import imagen from "../Images/plano2.png";
import "./ImageHorizontal.css";
import BusMaker from "./BusMaker";

const markerPosition = {
  x: 150,
  y: 500,
  size:"5vw"
};
const ImageHorizontal = () => {
  return (
    <div className="fullscreen-container">
      <img src={imagen} alt="Imagen" className="fullscreen-image" />
      <BusMaker data={markerPosition}></BusMaker>
    </div>
  );
};

export default ImageHorizontal;
