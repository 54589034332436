// Splash.js
import React from 'react';
import Image from "../Images/logounsam.png";
import Image2 from "../Images/tornamapbilo.png";
import "./Splash.css";
const Splash = (props) => {
    return(
    <div className="splash-container">
        <div className="splash">
            <img src={props.props.logo?Image:Image2} alt="Splash Screen"  style={props.props.logo?{ width: '80%', height: 'auto' }:{ width: '70%', height: 'auto' }} />
        </div>
    </div>
  );
};

export default Splash;


