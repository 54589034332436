import React, { useState, useEffect } from 'react';
import ImageSlider from "./Component/ImageSlider";
import ImageHorizontal from "./Component/ImageHorizontal";
import Splash from "./Component/Splash";
import "./App.css"
import Popup from './Popup';
import './Popup.css'; // Asegúrate de que el archivo CSS esté importado
import Snowfall from "react-snowfall";

function App() {
  const [showSplash, setShowSplash] = useState(true);
  const [showLogo, setShowLogo] = useState(true);
  const [esPantallaHorizontal, setEsPantallaHorizontal] = useState(window.matchMedia("(orientation: landscape)").matches);


  useEffect(() => {
    setTimeout(() => {
      setTimeout(()=>{
        setShowSplash(false);
      },2500)
      setShowLogo(false);
    }, 2000); // Muestra el Splash durante 3 segundos 
  }, []);

  // Para vista de pantalla vertical o horizontal
  useEffect(() => {
      const updatePantalla = () => {
        setEsPantallaHorizontal(window.matchMedia("(orientation: landscape)").matches);
      };

      window.addEventListener('resize', updatePantalla);

      return () => {
        window.removeEventListener('resize', updatePantalla);
      };
    }, []);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
      // Obtén la fecha actual en formato YYYY-MM-DD
      const today = new Date().toISOString().split('T')[0];
  
      // Verificar si la ventana emergente ya se ha mostrado hoy
      const lastPopupDate = localStorage.getItem('lastPopupDate');
  
      if (lastPopupDate !== today) {
        // Si no se ha mostrado hoy, muestra la ventana emergente después de 10 segundos
        const timer = setTimeout(() => {
          setShowPopup(true);
          // Actualiza la fecha almacenada a la fecha actual
          localStorage.setItem('lastPopupDate', today);
        }, 10000);
  
        // Limpia el temporizador si el componente se desmonta antes de que se alcance el tiempo
        return () => clearTimeout(timer);
      }
    }, []);
    const closePopup = () => {
      setShowPopup(false);
    };

  return (<>
  
    <div className="App">
      {showPopup && <Popup onClose={closePopup} />}
      {showSplash ? <Splash props={{logo:showLogo}} /> 
      :esPantallaHorizontal ? 
      <ImageHorizontal /> 
      :<ImageSlider  />}
      <Snowfall
      color="#d7d7d8"
      snowflakeCount={100}
      style={{zIndex:1200}}
      
    />
    </div>
  
  </>
   
  );
}


export default App;
